import type { FunctionComponent, PropsWithChildren } from 'react'
import type { HeaderProps } from '../'

import clsx from 'clsx'
import { useState } from 'react'

import Navigation, { NavigationItem } from '../../navigation'

import styles from './mobile-menu.module.scss'

interface MobileMenuProps extends PropsWithChildren<Pick<HeaderProps, 'mainNavigationItems'>> {
  className?: string
  bottomNavigationItems?: NavigationItem[],
  footerNavigationItems?: NavigationItem[],
  azLink?: string
}

const MobileMenu: FunctionComponent<MobileMenuProps> = (
  {
    mainNavigationItems = [],
    bottomNavigationItems = [],
    footerNavigationItems = [],
    className,
    azLink
  }
) => {
  const [open, setOpen] = useState<any>([])

  return <div className={clsx(
    styles.mobileMenu,
    className
  )}>
    <div className={styles.mobileMenuContent}>
      <Navigation
        azLink={azLink}
        variant={'vertical'}
        items={mainNavigationItems}
        className={clsx(styles.mobileMenuItems) }
        open={open}
        action={(targetId, { items, level }) => {
          if (!open.includes(targetId)){
            open[level] = targetId
            setOpen(open.slice(0, level+1))
          } else {
            setOpen([])
          }
        }}
      />

      <div className={styles.mobileMenuFooter}>
        {
          bottomNavigationItems &&
          <Navigation
            variant={'vertical'}
            items={bottomNavigationItems}
            className={clsx(
              styles.mobileMenuFooterList,
              styles.bottomItems
            )}
          />
        }
        {
          footerNavigationItems &&
          <Navigation
            variant={'top'}
            items={footerNavigationItems}
            className={styles.mobileMenuFooterLinks}
          />
        }
      </div>
    </div>
  </div>
}

export default MobileMenu
