import clsx from 'clsx'
import { FunctionComponent, useEffect, useRef } from 'react'
import { useState } from 'react'

import Icon from '../../atoms/icon'
import Paragraph from '../../atoms/paragraph'
import Title, { TitleElement, TitleSize } from '../../atoms/title'
import { Icons } from '../../design-tokens/iconography/icons'
import Button, { ButtonSize, ButtonVariant } from '../../molecules/button'
import Image from '../../molecules/image'

import styles from './popup.module.scss'

interface PopupProps {
  title?: string
  image?: string
  logo?: string
  icon?: string | Icons
  description?: string
  buttonLabel?: string
  closeLabel?: string
  closeCallback?: any
  link?: string
  open?: boolean
}

const Popup: FunctionComponent<PopupProps> = (
  {
    title,
    image,
    logo,
    icon,
    description,
    buttonLabel,
    link,
    closeLabel,
    closeCallback = () => {},
    open = false
  }
) => {
  const [active, setActive] = useState(open)
  const [removed, setRemoved] = useState(false)
  const popupRef = useRef<HTMLDivElement | null>(null)
  const lastFocusedElement = useRef<HTMLElement | null>(null)

  const handleClose = () => {
    setActive(false)
    setTimeout(() => setRemoved(true), 300)
    closeCallback()
    if (lastFocusedElement.current) {
      lastFocusedElement.current.focus()
    }

  }

  const trapFocus = (e: FocusEvent) => {
    if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
      const focusableElements = popupRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      if (focusableElements.length > 0) {
        (focusableElements[0] as HTMLElement).focus()
      }
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('focusin', trapFocus)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('focusin', trapFocus)
    }
  }, [])

  useEffect(() => {
    if (active) {
      scrollTo(0, 0)
      lastFocusedElement.current = document.activeElement as HTMLElement
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
    }

    return () => {
      document.documentElement.style.overflow = ''
    }
  }, [active])

  return !removed && <div
    ref={popupRef}
    role={'dialog'}
    aria-modal={'true'}
    aria-labelledby={'popup-title'}
    className={clsx(
      styles.popupWrapper,
      active && styles.active
    )}>
    <div className={styles.background}></div>
    <div className={styles.inner}>
      <Button className={styles.close} ariaLabel={'sluiten'} iconAfter={'close'} onClick={() => handleClose()}></Button>
      <div className={styles.popup}>
        {image && <Image priority src={image}/>}
        <div className={styles.content}>
          {!logo && icon && <Icon className={styles.icon} name={icon}/>}
          {logo && <Image className={styles.logo} noCrop src={logo}/>}
          {title && <Title size={TitleSize.Medium} tag={TitleElement.H2} id={'popup-title'}>{title}</Title>}
          {description && <Paragraph>{description}</Paragraph>}
          {buttonLabel && link && <Button
            className={styles.cta}
            iconAfter={'chevron-right'}
            href={link}
            darkMode
          >{buttonLabel}</Button>}
          {closeLabel && <Button
            className={styles.closeText}
            size={ButtonSize.small}
            variant={ButtonVariant.tertiary}
            onClick={() => handleClose()}
            darkMode
          >{closeLabel}</Button>}
        </div>
      </div>
    </div>
  </div>
}

export default Popup

export type {
  PopupProps
}
