import type { FunctionComponent } from 'react'
import type { NavigationItem } from '../navigation'

import clsx from 'clsx'
import { getYear } from 'date-fns'

import Icon from '../../atoms/icon'
import Logo from '../../atoms/logo'
import Title from '../../atoms/title'
import Button from '../../molecules/button'
import Navigation from '../navigation'

import AndroidCTA from './assets/android-cta.svg'
import BannerEmailImage from './assets/banner-email-image.svg'
import BannerSporzaImage from './assets/banner-sporza-image.svg'
import IosCTA from './assets/ios-cta.svg'
import styles from './footer.module.scss'

interface FooterProps {
  footerNavigationItems?: {
    socials: NavigationItem[],
    generalPages: NavigationItem[],
    programs: NavigationItem[],
    usefulLinks: NavigationItem[],
    vrtBrands: NavigationItem[],
    links: NavigationItem[],
  }
}

const Footer: FunctionComponent<FooterProps> = ({ footerNavigationItems = {} }) => {
  const copyrightText = 'vrt © ' + getYear(new Date())

  return <footer className={styles.footer}>
    <div className={styles.top}>
      <div className={styles.logoSocialWrapper}>
        <div className={styles.logo}>
          <Logo logo={'sporza'} darkMode link='https://sporza.be'/>
        </div>
        <div className={styles.social}>
          <Title tag="h2" title='VOLG ONS' className={styles.title}/>
          <Navigation
            variant={'pill-horizontal'}
            items={footerNavigationItems.socials}
            className={styles.appIcons}
          />
        </div>
      </div>

      <div className={styles.banner}>
        <div className={styles.content}>
          <Title tag="h2">
            <Icon
              name={'channels-sporza-livestream'}
              darkMode
              className={styles.titleIcon}/> SPORZA APP
          </Title>
          <Title
            className={styles.text}
            title='Volg Sporza op uw smartphone of tablet. Beschikbaar voor iOS en Android.'>
          </Title>
        </div>
        <div className={styles.cta}>
          <a
            href="https://itunes.apple.com/be/app/sporza/id878339906?l=nl&amp;mt=8"
            target="_blank"
            className={styles.ctaButtonLink} rel="noreferrer"
          ><IosCTA className={styles.ctaButton}/>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.fwc2014.vrt.and&amp;hl=nl"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.ctaButtonLink}
          ><AndroidCTA className={styles.ctaButton}/>
          </a>
        </div>
        <BannerSporzaImage className={styles.image}/>
      </div>

      <div className={clsx(styles.banner)}>
        <div className={styles.content}>
          <Title tag="h2">
            <Icon
              darkMode
              name={'e-mail-solid'}
              className={styles.titleIcon}/> SPORZA NIEUWSBRIEF
          </Title>
          <Title
            className={styles.text}
            title='Dagelijks mee met het belangrijkste sportnieuws met de sporza nieuwsbrief.'>
          </Title>
        </div>
        <Button
          variant={'primary'}
          className={styles.emailButton}
          iconAfter={'chevron-right'}
          href={'//sporza.be/nl/inschrijven-nieuwsbrief/'}> inschrijven
        </Button>
        <BannerEmailImage className={styles.image}/>
      </div>
    </div>

    <div className={styles.links}>
      <Navigation
        variant={'vertical'}
        items={footerNavigationItems.generalPages}
        className={styles.generalPages}
      />
      <Navigation
        variant={'vertical'}
        items={footerNavigationItems.usefulLinks}
        className={styles.usefulLinks}
      />
    </div>

    <div className={clsx(styles.links, styles.otherLinksSection)}>
      <Navigation
        variant={'vertical'}
        items={footerNavigationItems.links}
        listClassName="vrt-site-footer__links"
        className={styles.otherLinks}/>
      <span className={styles.copyright}>{copyrightText}</span>

    </div>
  </footer>
}

export default Footer

export type { FooterProps }
