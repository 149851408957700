import type { FunctionComponent } from 'react'

import { growthBookKey } from '@sporza/hooks'
import { getStorage, setStorage } from '@sporza/utils/storage'

import { useFeature } from '../../../../node_modules/@growthbook/growthbook-react'
import Popup from '../popup'

interface QualtricsPopupProps {
  key?: string
}

const QualtricsPopup: FunctionComponent<QualtricsPopupProps> = (
  {
    key = 'sporza-qualtrics-popup',

  }
) => {
  const feature = useFeature(key)
  console.log(feature)
  const {
    id,
    title,
    description,
    confirmLabel,
    confirmUrl,
    dismissLabel,
    titleArtUrl,
    topImageUrl
  } = feature.value || {}

  return feature.on && feature.source === 'force' ? <Popup
    open={feature.on}
    title={title}
    description={description}
    image={topImageUrl}
    logo={titleArtUrl}
    link={confirmUrl}
    buttonLabel={confirmLabel}
    closeLabel={dismissLabel}
    closeCallback={() => {
      const storage = getStorage(growthBookKey)
      const gbPopupsDisplayed = storage?.gbPopupsDisplayed || []

      if (!gbPopupsDisplayed.includes(id)) gbPopupsDisplayed.push(id)

      setStorage(growthBookKey, {
        ...storage,
        gbPopupsDisplayed
      })
    }}
  /> : null
}

export default QualtricsPopup

export type {
  QualtricsPopupProps
}
