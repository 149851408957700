import { getStorage, setStorage } from '@sporza/utils/storage'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { getEnv } from '../../apps/frontend-web/app/features/sso/helpers'
import { GrowthBook } from '../../node_modules/@growthbook/growthbook-react'

import { useAuth } from './use-auth'

const config = {
  dev: {
    featuresUrl: 'https://stag.vrt.be/features/features_stag_sporza.json',
    enableDevMode: true,
  },
  stag: {
    featuresUrl: 'https://stag.vrt.be/features/features_stag_sporza.json',
    enableDevMode: true,
  },
  prod: {
    featuresUrl: 'https://vrt.be/features/features_prod_sporza.json',
    enableDevMode: false,
  },
}

export const growthBookKey = 'sporza.growthbook'

const useGrowthBook = () => {
  const [growthBook, setGrowthBook] = useState<any>(new GrowthBook())
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const { marketingId, isLoggedIn } = useAuth()
  const growthbookStorage = getStorage(growthBookKey)

  const getUuid = () => {
    if (growthbookStorage.uuid) {
      return growthbookStorage.uuid
    } else {
      const uuid = uuidv4()
      setStorage(growthBookKey, {
        ...growthbookStorage,
        uuid
      })
      return uuid
    }
  }

  useEffect(() => {
    const initializeGrowthBook = async () => {
      try {
        const selectedConfig: any = config[getEnv() as keyof typeof config]
        const features = await fetch(selectedConfig.featuresUrl)
          .then((res) => res.json())
          .then((json) => json.features)

        growthBook.init({
          payload: {
            features,
            ...selectedConfig,
          }
        })

        growthBook.setAttributes({
          loggedIn: isLoggedIn,
          id: marketingId,
          clientName: 'sporza_web',
          deviceId: getUuid(),
          url: window.location.pathname,
          gbPopupsDisplayed: growthbookStorage.gbPopupsDisplayed || [],
        })
      } catch (err) {
        setError('Failed to initialize GrowthBook')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    initializeGrowthBook()
  }, [isLoggedIn, marketingId])

  return { growthBook, loading, error }
}

export default useGrowthBook
