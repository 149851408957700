import clsx from 'clsx'
import { FunctionComponent } from 'react'

import Hidden from '../../../atoms/hidden'

import styles from './menu-toggle.module.scss'

interface MenuToggleProps {
  className?: string
}

const MenuToggle: FunctionComponent<MenuToggleProps> = ({ className }) => {
  return <div
    className={clsx(
      styles.menuToggleContainer,
      className
    )}
  >
    <button type="button"
            className={clsx(styles.menuToggle, styles.menuToggleButtonWrapper)}
            onClick={e => {
              const selector = document.querySelector('#toggle-checkbox-menu') as HTMLInputElement
              selector.checked = !selector.checked
            }}>
      <Hidden>
        <span className={styles.menuToggleButtonLabelOpen}>open menu</span>
        <span className={styles.menuToggleButtonLabelClose}>sluit menu</span>
      </Hidden>
    </button>
    <label htmlFor="toggle-checkbox-menu" className={styles.menuToggle}>
      <span className={styles.menuToggleLineTop}></span>
      <span className={styles.menuToggleLineMiddle}></span>
      <span className={styles.menuToggleLineBottom}></span>
    </label>
  </div>
}

export default MenuToggle
