import { pageImpressionEvent } from '../events'

const trackPageChange = () => {
  // Page impression event
  pageImpressionEvent()
}

export {
  trackPageChange
}
